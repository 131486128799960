import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import './export.css';

const ProgrammeExport =({open, close, selectedRows, selectedColumns}) => {

  const newHeaders = [];
  const { enqueueSnackbar } = useSnackbar();

  if(open === true) {
      if(selectedColumns.custom_id !== undefined) {
        const custom_id = "custom_id";
        if(selectedColumns.custom_id === true) {
          const custom_id = { label: "custom_id", key: "custom_id" };
          newHeaders.push(custom_id);
        } else {
          selectedRows = selectedRows.map(({ [custom_id]: _, ...rest }) => rest); 
        }
      } else {
          const custom_id = { label: "custom_id", key: "custom_id" };
          newHeaders.push(custom_id);
      }
      
      if(selectedColumns.deal !== undefined) {
        const deal = "deal";
        if(selectedColumns.deal === true) {
          const deal = { label: "deal", key: "deal" };
          newHeaders.push(deal);
        } else {
          selectedRows = selectedRows.map(({ [deal]: _, ...rest }) => rest); 
        }
      } else {
        const deal = { label: "deal", key: "deal" };
          newHeaders.push(deal);
      }


      if(selectedColumns.title !== undefined) {
        const title = "title";
        if(selectedColumns.title === true) {
          const title = { label: "title", key: "title" };
          newHeaders.push(title);
        } else {
          selectedRows = selectedRows.map(({ [title]: _, ...rest }) => rest); 
        }
      } else {
        const title = { label: "title", key: "title" };
          newHeaders.push(title);
      }

    const handleDownloadAndClose = () => {
      handleSuccess();
      close();
    };

    const handleClose = () => {
      close();
    };

    const handleSuccess = () => {
      enqueueSnackbar('The .CSV file downloaded Successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        autoHideDuration: 3000,
      });
    };
    
    const csvLink =  {
        filename : "programmeExportData.csv",
        headers : newHeaders,
        data : selectedRows,
    };
  
    return (
      <Dialog
      open={open}
      onClose={() => {
        close();
      }}
      >
        <DialogContent>
          <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  height: '300px',
                  width: '600px',
                },
              }}>
              <DialogTitle sx={{ textAlign: 'center' }}>Download CSV</DialogTitle>
              <DialogContent sx={{ marginBottom: 2 }}>
                <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                  Are you sure want to download the .csv?
                </DialogContentText>             
                <DialogActions sx={{ textAlign: 'center'}}>
                    <CSVLink {...csvLink} class="csv-link" onClick={handleDownloadAndClose}> Download CSV</CSVLink>
                </DialogActions>
              </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} class="dialog-close-button">Close</Button>
                </DialogActions>
            </Dialog>
        </DialogContent>
      </Dialog>
    );
  }  
};

export default ProgrammeExport;
