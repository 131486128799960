import { useEffect, useState } from "react"
import { Box, Typography, Button, TextField, InputAdornment, FormControl } from "@mui/material"
import { useSnackbar } from 'notistack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



const StartDate = ({ index, options, setData, rowToEdit, remove }) => {


    
    const { enqueueSnackbar } = useSnackbar()
    const [rev, setRev] = useState(rowToEdit.client_rev_share ?? null)
    const [startDate, setStartDate] = useState(rowToEdit.start_date ?? null)
    const [endDate, setEndDate] = useState(rowToEdit.end_date ?? "2999-01-01")
    
    const [loading, setLoading] = useState(true)
    const dates = [];
    
    const handleDate = (value, header, setter) => setData(oldData => {
      // Ensure dates array is initialized
      const updatedDates = oldData.dates ? [...oldData.dates] : [];
    
      // Ensure the index is initialized
      if (!updatedDates[0]) {
        updatedDates[0] = {};
      }
    
      // Update the state and dates array based on the header
      if (header === "end_date") {
        setter(value); // Use the setter function provided
        updatedDates[0]['end_date'] = value;
      } else {
        setter(value); // Use the setter function provided
        updatedDates[0]['start_date'] = value;
      }
    
      // Return the updated oldData with the new dates array
      return { ...oldData, dates: updatedDates };
    });
  
    return (
      <div style={{ marginBottom: '1.5rem' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         
          <FormControl
            fullWidth
            margin="normal"
          >
            <DatePicker
              label={options.start_date?.label}
              value={startDate}
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} />}
              shouldRespectLeadingZeros
              onChange={(d) => {
                handleDate(d?.format("YYYY-MM-DD") ?? null, 'start_date', setStartDate)
              }}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
          >
            <DatePicker
              label={options.end_date?.label}
              value={endDate}
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} />}
              shouldRespectLeadingZeros
              maxDate={new Date('2999-12-31')}
              onChange={(d) => {
                handleDate(d?.format("YYYY-MM-DD") ?? null, 'end_date', setEndDate)
              }}
            />
          </FormControl>
        </LocalizationProvider>
      </div>
    )
  }

export default StartDate
