import {useNavigate, useSearchParams} from "react-router-dom";
import {useSnackbar} from "notistack";

import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import backgroundImage from "../images/loginBackground.jpeg";
import {Button, Typography} from "@mui/material";
import {useEffect} from "react";
import sendRequest from "../services/sendRequest";
import useScript from "../hook/useScript";
const role=localStorage.getItem("role")
const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {enqueueSnackbar} = useSnackbar();

  let client;

  useScript("https://accounts.google.com/gsi/client", () => {
    client = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_JWT_AUDIENCE,
      scope: "https://www.googleapis.com/auth/userinfo.email",
      ux_mode: "redirect",
      // redirect_uri: "http://localhost:3000", // for local development
      redirect_uri: window.location.origin,
      access_type: "offline",
      prompt: "consent",
    });
  });

  useEffect(() => {
    const code = searchParams.get("code");

    // user is already logged in
    if (
      localStorage.getItem(
        `${process.env.REACT_APP_GCP_PROJECT}_access` &&
          `${process.env.REACT_APP_GCP_PROJECT}_refresh`
      )
    ) {
      if(role=="read only - programmes only")
        navigate("/programmes");
      else
        navigate("/deals");
        
    } else if (code) {
      (async () => {
        const response = await sendRequest({
          requestType: "get",
          endpoint: `oauth-login?code=${code}`,
        });
        if (response?.status === 200) {
          const {access, refresh} = response.data;
          localStorage.setItem(
            `role`,
            response.data["role"]
          );
          localStorage.setItem(
            `superUser`,
            response.data["superUser"]
          );
          localStorage.setItem(
            `${process.env.REACT_APP_GCP_PROJECT}_access`,
            access
          );
          localStorage.setItem(
            `${process.env.REACT_APP_GCP_PROJECT}_refresh`,
            refresh
          );
          window.location.reload();
          navigate("/deals");
        } else if(response?.response?.status===403){
          const msg= response?.response?.data.split('|');
          enqueueSnackbar(msg[1], {
            variant: "warning",
            autoHideDuration: 6000,
          });
          enqueueSnackbar(msg[0], {
            variant: "warning",
            autoHideDuration: 6000,
          });
          setSearchParams("");
        }  else {
          enqueueSnackbar("There was an error logging in", {
            variant: "warning",
          });
          setSearchParams("");
        }
      })();
    }
  }, []);

  return (
    <Grid container component="main" sx={{height: "100vh"}}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(239, 236, 234, 1)",
          boxShadow: "150px 0px 0px rgba(0,0,0,.1)",
        }}
      >
        <Box
          sx={{
            // my: 'auto',
            // mx: 4,
            minHeight: 150,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* <Button onClick={() => console.log(getGoogleUrl())} >Test Login</Button> */}
          <Typography variant="h4" color="primary">
            PAYAWAY
          </Typography>
          <Button onClick={() => client.requestCode()}>Login</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
