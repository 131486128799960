import { Routes, Route } from "react-router-dom"
import MiniDrawer from "../navigation/navigation"

import PrivateRoutes from "./privateRoutes"
import LoginPage from "../pages/loginPage"
import ManagePage from '../pages/managePage'
import ManagePageBQ from '../pages/managePageBQ'
import { Main } from "../theme/theme"
import { Fragment } from "react"

const AppRoutes = ({ menuNavigationItems, open, setOpen }) => {

  return (
    <Routes>
      <Route exact path='/' element={<LoginPage />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/*" element={
          <>
            <MiniDrawer menuNavigationItems={menuNavigationItems} open={open} setOpen={setOpen} />
            <Main open={open} >
              <Routes>
                  {
                    menuNavigationItems.map(({ sectionName, headers }) => (
                      sectionName === "MANAGE" ?
                        headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter, customColumns }) => 
                          <Route key={pageLink} path={pageLink} element={
                            bigQueryTable ? 
                            <ManagePageBQ page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter} />
                            :
                            <ManagePage page={pageLink} columnsToShow={columnsToShow} clientFilter={sectionName === 'Deals'} defaultParams={defaultParams} />
                          } />)
                      : sectionName === "ACCESS" ?
                        headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter, customColumns }) => 
                          <Route key={pageLink} path={pageLink} element={
                            bigQueryTable ? 
                            <ManagePageBQ page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter} />
                            :
                            <ManagePage page={pageLink} columnsToShow={columnsToShow} clientFilter={sectionName === 'Deals'} defaultParams={defaultParams} />
                          } />)    
                      : sectionName === "REPORT" ?
                        headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter, customColumns }) => 
                          <Route key={pageLink} path={pageLink} element={
                            bigQueryTable ? 
                              <ManagePageBQ page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter} />
                              :
                              <ManagePage page={pageLink} columnsToShow={columnsToShow} clientFilter={sectionName === 'Deals'} defaultParams={defaultParams} customColumns={customColumns} />
                          } />)
                      : sectionName === "GOOGLE SHEETS" ?
                        headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter }) => 
                          <Route key={pageLink} path={pageLink} element={
                            bigQueryTable ? 
                              <ManagePageBQ page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter} />
                              :
                              <ManagePage page={pageLink} columnsToShow={columnsToShow} clientFilter={sectionName === 'Deals'} defaultParams={defaultParams} />
                          } />)
                      :
                        <Fragment key={sectionName} ></Fragment>
                    ))
                  }
              </Routes>
            </Main>
          </>
        } />
      </Route>
    </Routes>
  )
}

export default AppRoutes
