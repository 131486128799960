import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";
import {DataGridPro, getGridStringOperators} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import sendRequest from "../services/sendRequest";
import getOptions from "../services/getOptions";
import EditData from "../components/editData";
import ReportGen from "../components/reportGen";

import CustomToolbar from "../components/customToolbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import UnknownDealUpload from "../components/unknownDealUpload";
import UnknownProgrammeUpload from "../components/unknownProgrammeUpload";
import DealExport from "../components/dealExport";
import ProgrammeExport from "../components/programmeExport";
const ManagePage = ({
  page,
  columnsToShow,
  defaultParams,
  customColumns = {},
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams({});
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [editOrder, setEditOrder] = useState([]);
  const [columns, setColumns] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [options, setOptions] = useState({});
  const [rowToEdit, setRowToEdit] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [openUnknownDealUpload, setOpenUnknownDealUpload] = useState(false);
  const [openUnknownProgrammeUpload, setOpenUnknownProgrammeUpload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);

  const [openReportGen, setOpenReportGen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [selected, setSelected] = useState([]);

  const [reportList, setReportList] = useState([]);
  const [unSent, setUnSent] = useState([]);
  const [open, setOpen] = useState(false);
  const [checkSent, setCheckSent] = useState(false);
  const [sentReports, setSentReports] = useState([]);
  const [entries, setEntries] = useState([]);
  const [time, setTime] = useState(0);
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: page === "job" ? "desc" : "asc",
    },
  ]);
  const [openDealExport, setOpenDealExport] = useState(false);
  const [openProgrammeExport, setOpenProgrammeExport] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const role=localStorage.getItem("role")
  console.log("pagepagepagepage",page)
  const setOpenDealsProgrammeExport = () => {
    // Your export logic here
    // For example, you might be calling an API to export the selected rows

    // After the export is done, clear the selected rows
    setOpenDealExport(false)
    setOpenProgrammeExport(false)
    setSelectedRows([]);
    setSelected([])
    setEntries([]);
  };

  
  useEffect(() => {
    let fetched = false;
    async function getData() {
      setLoading(true);
      if(page==="users")
           await getOptions("permission");
           let data=JSON.parse(localStorage.getItem('data'));
      const metadata = await getOptions(page);
      console.log("metadatametadatametadata",metadata)
      if(page === "programmes"){
        delete metadata.series;
        await getOptions("deals_data");
       let deal_new_Data=JSON.parse(localStorage.getItem('deal_data'))
        if(deal_new_Data!=null && deal_new_Data!=undefined)
           metadata.deal.options=deal_new_Data
      }
      if (!metadata) {
        enqueueSnackbar("Failed to load Data Options!", {variant: "error"});
        return;
      }
      console.log(metadata);
      setOptions(metadata);

      const cols = await createColumns(metadata);
      if(page === "programmes"){
        for (let i = 0; i < cols.length; i++) {
          if(cols[i].field === "programme_rules"){
            delete cols[i];
          }
        }
      }
      setColumns(cols);

      const cvm = await createColumnVisibilityModel(metadata);
      setColumnVisibilityModel(cvm ?? false);

      const notReadOnly = Object.entries(metadata)
        .filter(([_, v]) => !v.read_only)
        .map(([k, _]) => k);
      setEditOrder(notReadOnly);
      const response = await sendRequest({
        requestType: "get",
        endpoint: `${page}/?${
          page === "task" ? "latest=true&" : ""
        }${searchParams.toString()}`,
      });

      console.log(response);

      if (
        page === "task" &&
        response.data.some(
          (item) => item.status === "running" || item.status === "queued"
        )
      ) {
        setRefresh((oldState) => !oldState);
      }

      // if (page === "task" && response.data[0].status === "running") {
      //   setRefresh((oldState) => !oldState);
      // }
      // if (
      //   page === "task" &&
      //   response.data.every(
      //     (item) => item.status === "success" || item.status === "failed"
      //   )
      // ) {
      //   setRefresh(false);
      // }

      // if (page === "task" && response.data[0].status === "success") {
      //   setRefresh(false);
      // }
      if (response.code) {
        enqueueSnackbar("Failed to load Data!", {variant: "error"});
      } else {
        if(page==="users")
            response.data.forEach(user => {
              const data1 = data.find(roleUser => roleUser.id === user.id);
              if (data1) {
                user.role = data1.role;  // Add the role if found
              } else {
                user.role = 'default';  // Assign a default role if not found
              }
            });
        console.log(response);
        setData(response.data);
      }
    }

    if (fetched) return;
    getData().finally(() => setLoading(false));
    return () => (fetched = true);
  }, [page, refresh]);

  const createColumnVisibilityModel = async (ops) => {
    const model = {};
    Object.keys(ops).forEach((columnName) => {
      if (columnsToShow && !columnsToShow.includes(columnName))
        model[columnName] = false;
    });
    return model;
  };

  const createColumns = async (ops) => [
    ...Object.keys(ops).map((header) => {
      let currentCol = {
        field: header,
        headerName: ops[header].label,
        flex: 1,
        // filterOperators: getGridStringOperators().filter(
        //   (operator) => operator.value === "equals"
        // ),
      };

      if (header in customColumns) {
        currentCol = {...currentCol, ...customColumns[header]};
      }
      return currentCol;
    }),
    ...(page === "job"
      ? [
          {
            field: "",
            headerName: "View",
            width: 150,

            renderCell: ({row}) => (
              <Button onClick={() => viewJob(row)}>View</Button>
            ),
          },
        ]
      : page === "quarterly_report" || role != "full access"
      ? []
      : [
          {
            field: "",
            headerName: "Edit",
            width: 150,
            renderCell: ({row}) => (
              <EditIcon onClick={() => openEditDialogue(row)} />
            ),
          },
        ]),
  ];

  const viewJob = (job) => {
    if (job?.id) {
      navigate(`/task?job=${job.id}`);
    } else {
      enqueueSnackbar("Invalid Job ID", {variant: "warning"});
    }
  };

  const openEditDialogue = (rowToEdit) => {
    if (!rowToEdit) {
      setOpenEdit(false);
    } else if (rowToEdit === "add") {
      setOpenEdit(true);
      setIsAdd(true);
      setRowToEdit(rowToEdit);
    } else {
      setOpenEdit(true);
      setIsAdd(false);
      setRowToEdit(rowToEdit);
    }
  };

  const selectedRowsForAssetAndUnknowAsset = (model) => {
    if(page === 'deals') {
      const seledtedRows = data.filter((x) => model.some((y) => y === x.custom_id_stem));
      setSelectedRows(seledtedRows)
    }
  };

  const selectedRowsForProgrammeExport = (model) => {
    if(page === 'programmes') {
      const seledtedRows = data.filter((x) => model.some((y) => y === x.custom_id));
      setSelectedRows(seledtedRows)
    }
  };

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSizeNumber) => {
    setPageSize(pageSizeNumber);
  };

  if (!columns) {
    return null;
  }

  const handleSendReport = async () => {
    // const statusChange = reportList.map((report) => {
    //   return {
    //     ...report,
    //     sent: true,
    //   };
    // });
    // setReportList(statusChange);
    setEntries([]);
    setSelected([]);
    setOpen(false);
    setLoading(true);
    //setProgress(true);
    if(reportList.length > 0) {
      const beforeStatus = await sendRequest({
        requestType: "post",
        endpoint: "email_status_update_before",
        requestData: reportList,
      });
    }
    const response = await sendRequest({
      requestType: "post",
      endpoint: "email",
      requestData: reportList,
    });    
    if(response && response.data && response.data.response_data && response.data.response_data.length > 0) {
      const statusUpdateResponse = await sendRequest({
        requestType: "post",
        endpoint: "email_status_update",
        requestData: response.data.response_data,
      });
    }
    setLoading(false);
    console.log(response);
    // const getResponse = await sendRequest({
    //   requestType: "get",
    //   endpoint: "email",
    //   requestData: reportList,
    // });

    // console.log(getResponse);
    // if (response?.status === 200 && response.data.data[0].status_code === 500) {
    //   enqueueSnackbar(response.data.data[0].error, {variant: "error"});
    // } else if (response?.status === 200) {
    //   enqueueSnackbar(response.data.data[0].message, {variant: "success"});
    // } else {
    //   enqueueSnackbar("Report sending failed", {variant: "error"});
    // }
    // //setProgress(false);
    // setLoading(false);
    // setRefresh((oldState) => !oldState);

    // console.log(response);
  };

  const handleUnsentReports = async () => {
    setEntries([]);
    setSelected([]);
    setOpen(false);
    setLoading(true);
    if(reportList.length > 0) {
      const beforeStatus = await sendRequest({
        requestType: "post",
        endpoint: "email_status_update_before",
        requestData: unSent,
      });
    }
    const response = await sendRequest({
      requestType: "post",
      endpoint: "email",
      requestData: unSent,
    });
    if(response && response.data && response.data.response_data && response.data.response_data.length > 0) {
      const statusUpdateUnSentResponse = await sendRequest({
        requestType: "post",
        endpoint: "email_status_update",
        requestData: response.data.response_data,
      });
    }
    if (response?.status === 200 && response.data.data[0].status_code === 500) {
      enqueueSnackbar(response.data.data[0].error, {variant: "error"});
    } else if (response?.status === 200) {
      enqueueSnackbar(response.data.data[0].message, {variant: "success"});
    } else {
      enqueueSnackbar("Report sending failed", {variant: "error"});
    }
    setLoading(false);
    console.log(response);
  };

  console.log(reportList);
  console.log(unSent);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const reportId = (row) => `${row.deal}${row.quarter}${row.year}`;
  //const uniqId = (row) => `${row.year}` + Math.random();

  const defaultId = (row) => {
    if (page === "exchange_rates") {
      return row.id + 1;
    }
    if (defaultParams && "row_id" in defaultParams)
      return defaultParams["row_id"];
    return row.id
      ? row.id
      : row.custom_id_stem
      ? row.custom_id_stem
      : row.custom_id;
  };
  return (
    <>
      <Box sx={{height: "100%", width: "100%"}}>
        <DataGridPro
          columns={columns ? columns : []}
          rows={data ? data : []}
          rowCount={data ? data?.data?.count : 0}
          // onRowClick={(params) => openEditDialogue(params.row)}
          sortModel={sortModel}
          pageSize={pageSize}
          rowsPerPageOptions={[100, 500, 5000]}
          onPageSizeChange={handlePageSizeChange}
          checkboxSelection
          disableSelectionOnClick
          columnVisibilityModel={{...columnVisibilityModel}}
          onColumnVisibilityModelChange={(model) =>
            setColumnVisibilityModel(model)
          }
          getRowId={page === "reports" ? reportId : defaultId}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
          }}
          loading={loading}
          pagination
          page={pageNumber}
          onPageChange={handlePageChange}
          components={{Toolbar: CustomToolbar}}
          componentsProps={{
            toolbar: {
              openEditDialogue,
              setOpenReportGen,
              page,
              selected,
              open,
              handleClickOpen,
              setOpenUnknownDealUpload,
              setOpenUnknownProgrammeUpload,
              setOpenDealExport,
              setOpenProgrammeExport,
              selectedRows
            },
          }}
          selectionModel={entries}
          onSelectionModelChange={(model) => {
            selectedRowsForAssetAndUnknowAsset(model);
            setEntries(model);
            console.log(model);
            selectedRowsForProgrammeExport(model);
            const selectedData = data.filter((row) =>
              model.includes(`${row.deal}${row.quarter}${row.year}`)
            );
            setSelected(model);

            const ReportListFilter = selectedData.map((item) => {
              return {
                //id: `${item.deal}${item.quarter}${item.year}`,
                deal_id: item.deal,
                deal_name:item.name,
                type: item.type,
                quarter: item.quarter,
                client: item.client,
                year: item.year,
                //report_file: item.report_file,
                sent: item.sent,
                canSend: item.canSend,
                //is_test: true,
              };
            });

            setReportList(ReportListFilter);

            const isSentcheck = ReportListFilter.every(
              (obj) => obj.sent === false
            );
            setCheckSent(isSentcheck);

            const unSentReports = ReportListFilter.filter(
              (item) => item.sent === false
            );
            setUnSent(unSentReports);

            const sentReports = ReportListFilter.filter(
              (item) => item.sent === true
            );
            setSentReports(sentReports);
          }}
          isRowSelectable={(params) => {
            return params.row.status !== "Disabled";
          }}
        />
        <EditData
          open={openEdit}
          openEditDialogue={openEditDialogue}
          options={options}
          order={editOrder}
          rowToEdit={rowToEdit}
          isAdd={isAdd}
          page={defaultParams?.["edit_endpoint"] ?? page}
          setRefresh={setRefresh}
        />
        <UnknownDealUpload
          open={openUnknownDealUpload}   
          close={() => setOpenUnknownDealUpload(false)}       
        ></UnknownDealUpload>
        <UnknownProgrammeUpload
          open={openUnknownProgrammeUpload}   
          close={() => setOpenUnknownProgrammeUpload(false)}       
        ></UnknownProgrammeUpload>
        <ReportGen
          open={openReportGen}
          close={() => setOpenReportGen(false)}
          selected={selected}
          setRefresh={setRefresh}
        ></ReportGen>

        {checkSent ? (
          <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Report Email</DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure, You want to send the reports?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={handleSendReport} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Report Email</DialogTitle>

            <DialogContentText
              id="alert-dialog-description"
              textAlign="center"
              fontWeight="bold"
            >
              {sentReports.length} Deal have already sent their reports.
            </DialogContentText>
            <Divider />
            <DialogContent>
              <Stack direction="row" spacing={2} justifyContent="center">
                <List>
                  <DialogContentText id="alert-dialog-description">
                    Already sent Reports
                  </DialogContentText>
                  {sentReports.map((report) => {
                    return (
                      <ListItem
                        key={`${report.deal_id}${report.quarter}${report.year}`}
                      >
                        <ListItemText primary={report.deal_id}></ListItemText>
                      </ListItem>
                    );
                  })}
                </List>

                <Divider orientation="vertical" flexItem />

                <List>
                  <DialogContentText id="alert-dialog-description">
                    Unsent Reports
                  </DialogContentText>

                  {unSent.map((report) => {
                    return (
                      <ListItem
                        key={`${report.deal_id}${report.quarter}${report.year}`}
                      >
                        <ListItemText primary={report.deal_id}></ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancle</Button>
              <Button onClick={handleSendReport} autoFocus>
                Send All Reports
              </Button>
              <Button onClick={handleUnsentReports} autoFocus>
                Send Unsent Reports
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* {progress ? (
          <ProgressBar
            time={time}
            progress={progress}
            setProgress={setProgress}
          />
        ) : null} */}
        <DealExport
          open={openDealExport}
          close={() => setOpenDealsProgrammeExport(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></DealExport>
        <ProgrammeExport
          open={openProgrammeExport}
          close={() => setOpenDealsProgrammeExport(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></ProgrammeExport>
      </Box>
    </>
  );
};

export default ManagePage;

// https://localhost:3000/deals?custom_id_stem=SOT
