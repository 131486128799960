import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import './export.css';

const DealExport =({open, close, selectedRows, selectedColumns}) => {

  const newHeaders = [];
  const { enqueueSnackbar } = useSnackbar();

  if(open === true) {
      if(selectedColumns.client !== undefined) {
        const client = "client";
        if(selectedColumns.client === true) {
          const client = { label: "client", key: "client" };
          newHeaders.push(client);
        } else {
          selectedRows = selectedRows.map(({ [client]: _, ...rest }) => rest); 
        }
      } else {
          const client = { label: "client", key: "client" };
          newHeaders.push(client);
      }

      
      if(selectedColumns.client_id !== undefined) {
        const client_id = "client_id";
        if(selectedColumns.client_id === true) {
          const client_id = { label: "client_id", key: "client_id" };
          newHeaders.push(client_id);
        } else {
          selectedRows = selectedRows.map(({ [client_id]: _, ...rest }) => rest); 
        }
      } else {
        const client_id = { label: "client_id", key: "client_id" };
          newHeaders.push(client_id);
      }


      if(selectedColumns.can_send !== undefined) {
        const can_send = "can_send";
        if(selectedColumns.can_send === true) {
          const can_send = { label: "can_send", key: "can_send" };
          newHeaders.push(can_send);
        } else {
          selectedRows = selectedRows.map(({ [can_send]: _, ...rest }) => rest); 
        }
      } else {
        const can_send = { label: "can_send", key: "can_send" };
          newHeaders.push(can_send);
      }


      if(selectedColumns.client_rev_share !== undefined) {
        const client_rev_share = "client_rev_share";
        if(selectedColumns.client_rev_share === true) {
          const client_rev_share = { label: "client_rev_share", key: "client_rev_share" };
          newHeaders.push(client_rev_share);
        } else {
          selectedRows = selectedRows.map(({ [client_rev_share]: _, ...rest }) => rest); 
        }
      } else {
        const client_rev_share = { label: "client_rev_share", key: "client_rev_share" };
          newHeaders.push(client_rev_share);
      }


      if(selectedColumns.currency !== undefined) {
        const currency = "currency";
        if(selectedColumns.currency === true) {
          const currency = { label: "currency", key: "currency" };
          newHeaders.push(currency);
        } else {
          selectedRows = selectedRows.map(({ [currency]: _, ...rest }) => rest); 
        }
      } else {
        const currency = { label: "currency", key: "assetsId" };
          newHeaders.push(currency);
      }


      if(selectedColumns.custom_id_stem !== undefined) {
        const custom_id_stem = "custom_id_stem";
        if(selectedColumns.custom_id_stem === true) {
          const custom_id_stem = { label: "custom_id_stem", key: "custom_id_stem" };
          newHeaders.push(custom_id_stem);
        } else {
          selectedRows = selectedRows.map(({ [custom_id_stem]: _, ...rest }) => rest); 
        }
      } else {
        const custom_id_stem = { label: "custom_id_stem", key: "custom_id_stem" };
          newHeaders.push(custom_id_stem);
      }


      if(selectedColumns.min_guarantee !== undefined) {
        const min_guarantee = "min_guarantee";
        if(selectedColumns.min_guarantee === true) {
          const min_guarantee = { label: "min_guarantee", key: "min_guarantee" };
          newHeaders.push(min_guarantee);
        } else {
          selectedRows = selectedRows.map(({ [min_guarantee]: _, ...rest }) => rest); 
        }
      } else {
        const min_guarantee = { label: "min_guarantee", key: "min_guarantee" };
          newHeaders.push(min_guarantee);
      }


      if(selectedColumns.name !== undefined) {
        const name = "name";
        if(selectedColumns.name === true) {
          const name = { label: "name", key: "name" };
          newHeaders.push(name);
        } else {
          selectedRows = selectedRows.map(({ [name]: _, ...rest }) => rest); 
        }
      } else {
        const name = { label: "name", key: "name" };
          newHeaders.push(name);
      }    
  
      if(selectedColumns.permitted_deduction !== undefined) {
        const permitted_deduction = "permitted_deduction";
        if(selectedColumns.permitted_deduction === true) {
          const permitted_deduction = { label: "permitted_deduction", key: "permitted_deduction" };
          newHeaders.push(permitted_deduction);
        } else {
          selectedRows = selectedRows.map(({ [permitted_deduction]: _, ...rest }) => rest); 
        }
      } else {
        const permitted_deduction = { label: "permitted_deduction", key: "permitted_deduction" };
          newHeaders.push(permitted_deduction);
      }

      if(selectedColumns.report_currency !== undefined) {
        const report_currency = "report_currency";
        if(selectedColumns.report_currency === true) {
          const report_currency = { label: "report_currency", key: "report_currency" };
          newHeaders.push(report_currency);
        } else {
          selectedRows = selectedRows.map(({ [report_currency]: _, ...rest }) => rest); 
        }
      } else {
        const report_currency = { label: "report_currency", key: "report_currency" };
          newHeaders.push(report_currency);
      }

      if(selectedColumns.report_emails !== undefined) {
        const report_emails = "report_emails";
        if(selectedColumns.report_emails === true) {
          const report_emails = { label: "report_emails", key: "report_emails" };
          newHeaders.push(report_emails);
        } else {
          selectedRows = selectedRows.map(({ [report_emails]: _, ...rest }) => rest); 
        }
      } else {
        const report_emails = { label: "report_emails", key: "report_emails" };
          newHeaders.push(report_emails);
      }

      if(selectedColumns.report_type !== undefined) {
        const report_type = "report_type";
        if(selectedColumns.report_type === true) {
          const report_type = { label: "report_type", key: "report_type" };
          newHeaders.push(report_type);
        } else {
          selectedRows = selectedRows.map(({ [report_type]: _, ...rest }) => rest); 
        }
      } else {
        const report_type = { label: "report_type", key: "report_type" };
          newHeaders.push(report_type);
      }

      if(selectedColumns.status !== undefined) {
        const status = "status";
        if(selectedColumns.status === true) {
          const status = { label: "status", key: "status" };
          newHeaders.push(status);
        } else {
          selectedRows = selectedRows.map(({ [status]: _, ...rest }) => rest); 
        }
      } else {
        const status = { label: "status", key: "status" };
          newHeaders.push(status);
      }

      if(selectedColumns.type !== undefined) {
        const type = "type";
        if(selectedColumns.type === true) {
          const type = { label: "type", key: "type" };
          newHeaders.push(type);
        } else {
          selectedRows = selectedRows.map(({ [type]: _, ...rest }) => rest); 
        }
      } else {
        const type = { label: "type", key: "type" };
          newHeaders.push(type);
      }
    const handleDownloadAndClose = () => {
      handleSuccess();
      close();
    };

    const handleClose = () => {
      close();
    };

    const handleSuccess = () => {
      enqueueSnackbar('The .CSV file downloaded Successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        autoHideDuration: 3000,
      });
    };
    
    const csvLink =  {
        filename : "dealExportData.csv",
        headers : newHeaders,
        data : selectedRows,
    };
  
    return (
      <Dialog
      open={open}
      onClose={() => {
        close();
      }}
      >
        <DialogContent>
          <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  height: '300px',
                  width: '600px',
                },
              }}>
              <DialogTitle sx={{ textAlign: 'center' }}>Download CSV</DialogTitle>
              <DialogContent sx={{ marginBottom: 2 }}>
                <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                  Are you sure want to download the .csv?
                </DialogContentText>             
                <DialogActions sx={{ textAlign: 'center'}}>
                    <CSVLink {...csvLink} class="csv-link" onClick={handleDownloadAndClose}> Download CSV</CSVLink>
                </DialogActions>
              </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} class="dialog-close-button">Close</Button>
                </DialogActions>
            </Dialog>
        </DialogContent>
      </Dialog>
    );
  }  
};

export default DealExport;
